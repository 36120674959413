(function($, WS, ob_set) {
	'use strict';

	// require WS utility namespace
	if (typeof WS === 'undefined') {
		throw new Error('Whitespace utility namespace does not exist');
	}

	/**
	 */
	var pcAnywhere = function(settings) {
		var my;

		my = {
			enable: function() {
				settings = ob_set(settings || {}, pcAnywhere.defaults);
				my.parseUi();
			},

			parseUi: function() {
				var el, btn = $(settings.selectors.target);

				if (btn.is('a') || btn.is('button')) {
					btn.on('click', function(event) {
						event.preventDefault();
						my.search($(settings.selectors.search).val());
					});
				}

				// hide things until we need them, but not on edit
				if (window.location.pathname.indexOf('edit') < 0) {
					$('.map-line-1').parent().hide();
					$('.map-line-2').parent().hide();
					$('.map-line-3').parent().hide();
					$('.map-town').parent().hide();
					$('.map-postcode').parent().hide();
				}

				// delegate get-address links
				$(settings.selectors.display).on('click',
					settings.selectors.trigger,
					function(event) {
						var id = $(this).data('id'), location;

						// do we need to requery the api
						if ($(this).data('type') === 'Find') {
							my.search($(this).text());
							return;
						}

						// check the location id
						location = id.split('|').slice(-1)[0];
						if (!location.length) {
							return;
						}

						if (id.length) {
							$.getJSON('//services.postcodeanywhere.co.uk/Geocoding/UK/Geocode/v2.00/json3.ws?callback=?', {
								'Key': 'PY74-PJ44-JJ84-RD79',
								'Location': id.split('|').slice(-1)[0]
							}, function (data) {
								// results
								if (data.Items.length == 1 && typeof(data.Items[0].Error) !== 'undefined') {
									return {
										'message': data.Items[0].Description
									};
								} else {
									// we have a lat/lng, get the address
									$.getJSON('//services.postcodeanywhere.co.uk/CapturePlus/Interactive/Retrieve/v2.10/json3.ws?callback=?', {
										'Key': 'PY74-PJ44-JJ84-RD79',
										'Id': id
									}, function (address) {
										// results
										if (address.Items.length == 1 && typeof(address.Items[0].Error) !== 'undefined') {
											return {
												'message': address.Items[0].Description
											};
										} else {
											if (address.Items) {
												my.processAddress(data.Items, address.Items);
											}
										}
									});
								}
							});
						}

						event.preventDefault();
					}
				); // get-address

				$(settings.selectors.display).on('click',
					settings.selectors.clear,
					function(event) {
						// clear the lookup
						$(settings.selectors.display).empty().hide();
						$(settings.selectors.search).val('');
						event.preventDefault();
					}
				);
			},

			search: function(postcode) {
				if (!postcode.length) {
					return;
				}

				$.getJSON('//services.postcodeanywhere.co.uk/CapturePlus/Interactive/Find/v2.10/json3.ws?callback=?', {
					'Key': 'PY74-PJ44-JJ84-RD79',
					'SearchTerm': postcode,
					'PreferredLanguage': 'English',
					'Country': 'GBR'
				}, function(data) {
					// results
					if (data.Items.length == 1 && typeof(data.Items[0].Error) !== 'undefined') {
						return {
							'message': data.Items[0].Description
						};
					} else {
						if (data.Items) {
							my.processResults(data.Items);
						}
					}
				});
			},

			processAddress: function(data, address) {
				if (!data.length) {
					return;
				}

				var i, marker, position;

				// clear the lookup
				$(settings.selectors.display).empty().hide();

				// do we have a map?
				if (window.venueMarkers) {
					// update the lat/lng hidden fields
					$(settings.form.lat).val(data[0].Latitude);
					$(settings.form.lng).val(data[0].Longitude);

					// clear old markers
					for (i = 0; i < window.venueMarkers.length; i += 1) {
						window.venueMarkers[i].setMap(null);
					}
					window.venueMarkers = [];

					// set new marker
					marker = new window.google.maps.Marker({
						'animation': window.google.maps.Animation.DROP,
						'draggable': true,
						'position': {
							'lat': data[0].Latitude,
							'lng': data[0].Longitude
						},
						'map': window.venueMap,
					});

					marker.addListener('dragend', function() {
						var lat = marker.getPosition().lat(),
							lng = marker.getPosition().lng();
						$(settings.form.lat).val(lat);
						$(settings.form.lng).val(lng);
					});

					window.venueMarkers.push(marker);

					// move to new position
					position = new window.google.maps.LatLng(
						data[0].Latitude, data[0].Longitude);
					window.venueMap.panTo(position);
				}

				// update the address
				$('.map-line-1').val(address[0].Line1).parent().show();
				$('.map-line-2').val(address[0].Line2).parent().show();
				$('.map-line-3').val(address[0].ProvinceName).parent().show();
				$('.map-town').val(address[0].City).parent().show();
				$('.map-postcode').val(address[0].PostalCode).parent().show();

				// get the user region
				my.getUserRegion(address[0].PostalCode);
			},

			processResults: function(data) {
				// clear the display ...
				$(settings.selectors.display).empty().show();

				// ... iterate over and display the lookup results.
				$.each(data, function(i, row) {
					if (row.Next === 'Retrieve' || row.Next === 'Find') {
						if (! row.Id.match("_")) {
							$(settings.selectors.display).append(
								'<li><a href="#" class="get-address" data-type="' + row.Next + '" data-id="' + row.Id + '">' + row.Text + '</a></li>');
						}
						else {
							var cc = row.Id.split('|')[0];
							var id = row.Id.split('|')[1].split('_')[0];

							$(settings.selectors.display).append(
								'<li><a href="#" class="get-address" data-type="' + row.Next + '" data-id="' + cc + '|' + id + '">' + row.Text + '</a></li>');
						}
					}
				});

				$(settings.selectors.display).prepend(
					'<li><a href="#" class="clear-address">Search again <i class="fa fa-refresh pull-right" aria-hidden="true"></i></a></li>');
			},

			getUserRegion: function(postcode) {
				console.log('getUserRegion', postcode);
			},

			setUserRegion: function(postcode) {
				console.log('setUserRegion', 'we know the ID at this point', postcode);
			},

			validatePostcode: function(postcode) {
				var re = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/gi;
				return re.test(postcode.replace(/\s/g, ''));
			}
		}

		my.enable(settings);
	};

	/**
	 * The default WS.pcAnywhere settings
	 * @typedef WS.pcAnywhere.defaults
	 * @property {object} selectors - Default selector settings.
	 */
	pcAnywhere.defaults = {
		'selectors': {
			'target': 'a.postcode-lookup',
			'search': 'input.postcode-lookup'
		}
	};

	$.WS.createModule('pcAnywhere', pcAnywhere);
	window.WS.pcAnywhere = pcAnywhere;
}(
	window.jQuery,
	window.WS,
	window.ob_set
));
