;(function($, WS, options) {
	'use strict';

	// Set up jQuery to send CSRF tokens with every request
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	// Main application
	var App = {

		// if we don't have a root node, do nothing
		init: function() {
			var $r;

			if (!options.cache.$root.length) {
				return; // nothing to do here
			} else {
				// root shortcut
				$r = options.cache.$root;
			}

		/**
		 * Loose
		 */
			// tooltips
			$('[data-toggle="tooltip"]').tooltip();

			if ($r.data('shepherd') === 'no-tour') {
				$('li.shepherd').remove();
			}

			$r.find('a.navbar-mobile').on('click', function(event) {
				var $t = $(this), $n = $('#main-navigation');
				event.preventDefault();

				if ($t.hasClass('active')) {
					$n.animate({
						'left': -220
					}, 180, 'easeOutExpo', function() {
						$t.removeClass('active');
					});
				} else {
					$n.animate({
						'left': 0
					}, 180, 'easeOutExpo', function() {
						$t.addClass('active');
					});
				}
			});

			$r.find('a.submit-status').on('click', function(event) {
				event.preventDefault();
				$('input#frm-action').val('status');
				$('input#frm-params').val($(this).text());
				$('form#frm-multiple').submit();
			});

			$r.find('a.submit-email').on('click', function(event) {
				event.preventDefault();
				$('input#frm-action').val('email');
				$('input#frm-params').val($(this).data('email'));
				$('form#frm-multiple').submit();
			});

			$r.find('a.submit-attendees').on('click', function(event) {
				event.preventDefault();
				$('input#frm-action').val('attendees');
				$('input#frm-params').val($(this).data('events[]'));
				$('form#frm-multiple').submit();
			});

			$r.find('.submit-duplicate').on('click', function(event) {
				event.preventDefault();
				$('input#frm-action').val('duplicate');
				$('input#frm-params').val($(this).data('events[]'));
				$('form#frm-multiple').submit();
			});

			$r.find('input[name="no_company_name"]').on('change', function() {
				if ($(this).is(':checked')) {
					$r.find('form input[name="attendee[company_name]"]').attr('readonly', true);
					var default_company_name = $r.find('form input[name="attendee[first_name]"]').val() + ' ' + $r.find('form input[name="attendee[last_name]"]').val();
					$r.find('form input[name="attendee[company_name]"]').val(default_company_name);
				} else {
					$r.find('form input[name="attendee[company_name]"]').removeAttr('readonly');
					$r.find('form input[name="attendee[company_name]"]').val('');
				}
			});

			$r.find('input[data-disables]').each(function() {
				var input = this;

				App.checkDisablerState(input);

				$(this).on('change', function(event) {
					App.checkDisablerState(input);
				});
			});

			$r.find('.wait').on('click', function(event) {
				event.preventDefault();
				$(this).prop('disabled', 'disabled').html('<i class="fa fa-refresh fa-spin"></i>');
				$('form.waiting').submit();
			});

			// checkbox is_invite reveal
			$r.find('input.reveal').each(function(index, el) {
				// sets initial state
				if ($(el).is(':checked')) {
					$('div.revealable').show();
				} else {
					$('div.revealable').hide();
				}

				$(el).on('click', function (event) {
					if ($(this).is(':checked')) {
						$('div.revealable').show();
						$r.find('input#frm-has_waiting_list').each(function (index, el) {
							$(el)
								.prop('checked', false)
								.attr('disabled', true);
						});
					} else {
						$('div.revealable').hide();
						$r.find('input#frm-has_waiting_list').each(function (index, el) {
							$(el)
								.prop('checked', true)
								.attr('disabled', false);
						});
					}
				});
			});

			// checkbox webinar reveal
			$r.find('input.reveal_webinar').each(function(index, el) {
				// sets initial state
				if ($(el).is(':checked')) {
					$('div.revealable_webinar').show();
				} else {
					$('div.revealable_webinar').hide();
				}

				$(el).on('click', function(event) {
					if ($(this).is(':checked')) {
						$('div.revealable_webinar').show();
					} else {
						$('div.revealable_webinar').hide();
					}
				});
			});

			// clickable dashboard cards
			$r.find('.clickable .card-block').bind('click', function(event) {
				var data = { $link: null };
				if ((data.$link = $(this).find('a')) && event.target != data.$link[0]) {
					data.$link[0].click();
				}
			});

			// select a catlogue
			var cTemplateSelect = $('#js_select_template');
			var cTemplateOptions = $('#js_select_template option');

			$('#js_select_region').on('change', function(event) {
				var value = $(this).val();
				var catalogueValues = $('#js_event_regions').val().split('|');
				var matches = [];
				var options = [];

				$.each(catalogueValues, function(index, ids) {
					var tplId = ids.split(':');
					if (tplId[1] === value) {
						matches.push(tplId[0]);
					}
				});

				if (value == '') {
					options = cTemplateOptions;
				} else {
					// only show ones with this value
					$.each(cTemplateOptions, function(index, option) {
						if (matches.indexOf(option.value) != -1) {
							options.push(option);
						}
					});
				}

				if (options.length < 1) {
					cTemplateSelect
						.empty()
						.append('<option>No templates ...</option>')
						.prop('selectedIndex', 0);
				} else {
					cTemplateSelect
						.empty()
						.append(cTemplateOptions[0])
						.append(options)
						.prop('selectedIndex', 0);
				}
			});

			// Populate a select2 element with the values from a hidden field.
			$('.set-all-parameters').on('click', function () {
				var $values = $(this)
					.closest('.select2-select')
					.find('.get-all-parameters').val();

				$values = $values.split(',');

				$values.forEach(function (value, key) {
					$values[key] = value.trim();
				});

				var $select2 = $(this)
					.closest('.select2-select')
					.find('.select2');

				$select2.val($values).trigger('change');
			});

		/**
		 * Sortable defaults
		 */

		/**
		 * Whitespace plugins and associated functionality
		 */
			// setup shepherd tours
			App.shep = WS.shep({
				'selectors': {
					'target': 'a.shepherd'
				}
			});

			// setup ui alerts and confirms
			App.dialog = WS.dialog({
				'selectors': {
					'confirm': '.confirm',
					'templates': {
						'confirm': 'script.confirm'
					}
				}
			});

			// setup remoting for bucket adding/removing
			App.remote = WS.remote({
				'selectors': {
					'form': 'form.bucket'
				},
				'callbacks':  {
					'done': function(target, response) {
						var total;

						// update the control and bucket total
						target.replaceWith($(response.notification.fragement));

						if (response.notification.total == 1) {
							total = response.notification.total + ' event';
						} else {
							total = response.notification.total + ' events';
						}

						$('.bucket-total').text(total);

						// notify the user
						$.notify({
							'title': '<strong>Success!</strong>&nbsp;',
							'message': response.notification.message
						}, {
							'allow_dismiss': false,
							'animate': {
								'enter': 'animated fadeIn',
								'exit': 'animated fadeOut'
							},
							'delay': 400,
							'placement': {
								'align': 'left',
								'from': 'bottom'
							},
							'type': 'success',
							'template':
								'<div data-notify="container" class="alert alert-{0} notification" role="alert">' +
									'<button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button>' +
									'<span data-notify="icon" class="notification-icon"><i class="fa fa-check-circle-o"></i></span>' +
									'<span data-notify="title">{1}</span>' +
									'<span data-notify="message">{2}</span>' +
									'<a href="{3}" target="{4}" data-notify="url"></a>' +
								'</div>'
						});
					},
					'fail': function(target, response) {
						// notify the user
						$.notify({
							'title': '<strong>Error!</strong>&nbsp;',
							'message': 'An error occured.'
						}, {
							'allow_dismiss': false,
							'animate': {
								'enter': 'animated fadeIn',
								'exit': 'animated fadeOut'
							},
							'delay': 400,
							'placement': {
								'align': 'left',
								'from': 'bottom'
							},
							'type': 'danger',
							'template':
								'<div data-notify="container" class="alert alert-{0} notification" role="alert">' +
									'<button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button>' +
									'<span data-notify="icon" class="notification-icon"><i class="fa fa-times-circle-o"></i></span>' +
									'<span data-notify="title">{1}</span>' +
									'<span data-notify="message">{2}</span>' +
									'<a href="{3}" target="{4}" data-notify="url"></a>' +
								'</div>'
						});
					}
				}
			});

		/**
		 * Third Party plugins, helpers, bootstrap plugins and other associated functionality
		 */

			this.plugins.selects($r.find('select.select2'));
			this.plugins.datetime($r.find('input.datetime'));
			this.plugins.redactor($r.find('.redactor'));
			this.plugins.preview($r.find('.preview'));
			this.plugins.counter($r.find('.character-counter'));
			this.plugins.checkall($r.find('[name="select-all"]'));

		/**
		 * Google Maps, Postcode Anywhere
		 */
			if ($r.find('.lookup-map').length) {
				// fire the async callback when this lib is loaded
				$(window).bind('gMapsLoaded', function() {
					App.plugins.maps($r.find('.lookup-map'));
				});

				window.loadGoogleMaps();
			}
		},

		/**
		 * Third party plugins and associated functionality
		 */
		plugins: {
			// activate Select2 for tag selects
			selects: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				var options, $target = $(target);

				$(target).each(function(index, el) {
					var $el = $(el);

					if ($el.data('max')) {
						options = {
							'placeholder': 'Click or start typing to search ...',
							'maximumSelectionLength': $el.data('max')
						};
					} else {
						options = {
							'placeholder': 'Click or start typing to search ...'
						};
					}

					$el.select2(options);
				});
			}, // select

			// activate bootstrap-date for datetime
			datetime: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				var $target = $(target);

				$target.datetimepicker({
					'autoclose': true,
					'format': "dd M yyyy / HH:ii P",
					'fontAwesome': true,
					'showMeridian': true,
					'todayBtn': true
				});
			}, // datetime

			// activate preview for rich textareas
			preview: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				$('.html-preview').on('click', function(event) {
					var $editor = $(this).parents('.form-group').find('.redactor-editor'),
						content = $editor.html();

					$(target).html(content);
				});
			}, // preview

			// activate rich textareas
			redactor: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				$(target).redactor();
			}, // redactor

			counter: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				$(target).each(function(index, el) {
					var $el = $(el), $textarea, $editor, max;

					// initial value
					$textarea = $el.prev('textarea');
					max = $textarea.prop('maxlength');

					// change value on key up
					if (typeof max !== 'undefined') {
						$el.html((max - $textarea.val().length) + ' characters remaining');
						$textarea.on('keyup', function(event) {
							$el.html((max - $textarea.val().length) + ' characters remaining');
						});
					}
				});
			}, // counter

			maps: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				var lat, lng, marker, location;
				lat = $('#latitude').val();
				lng = $('#longitude').val();

				if (lat.length && lng.length) {
					location = {
						'lat': parseFloat(lat),
						'lng': parseFloat(lng)
					};
				} else {
					location = {
						'lat': 55.9518711,
						'lng': -3.1834713000000647
					};
				}

				window.venueMarkers = [];
				window.venueMap = new window.google.maps.Map(
					document.getElementById('map'), {
						'center': location,
						'zoom': 16
					}
				);

				marker = new google.maps.Marker({
					'animation': window.google.maps.Animation.DROP,
					'draggable': true,
					'position': location,
					'map': window.venueMap,
				});

				marker.addListener('dragend', function() {
					var lat = marker.getPosition().lat(),
						lng = marker.getPosition().lng();
					$('#latitude').val(lat);
					$('#longitude').val(lng);
				});

				window.venueMarkers.push(marker);
			}, // maps

			// checkall checkboxs
			checkall: function(target) {
				if (!target || typeof target === 'undefined') {
					return;
				}

				// enable visibility of classed TD/THs if there are checkboxes to use
				$('.select-all-on-demand').each(function() {
					var $table = $(this),
						$checkboxes = $table.find('.select-all-body input.select-all');

					if ($checkboxes.length) {
						$table.addClass('select-all-on-demand--active');
					}
				});

				// select or deselect all related checkboxes
				$(target).each(function(index, el) {
					$(el).change(function() {
						var selected = $('input.select-all:checked').length;

						$('input.select-all').prop('checked', $(this).prop('checked'));

						// is anything checked?
						if (selected > 0) {
							$('button.select-all').prop('disabled', false);

							if (selected === 1) {
								$('button.select-one').prop('disabled', false);
							} else {
								$('button.select-one').prop('disabled', true);
							}
						} else {
							$('button.select-all').prop('disabled', true);
							$('button.select-one').prop('disabled', true);
						}
					});
				});

				$('input.select-all').change(function() {
					var selected = $('input.select-all:checked').length;

					// is anything checked?
					if (selected > 0) {
						$('button.select-all').prop('disabled', false);

						if (selected === 1) {
							$('button.select-one').prop('disabled', false);
						} else {
							$('button.select-one').prop('disabled', true);
						}
					} else {
						$('button.select-all').prop('disabled', true);
						$('button.select-one').prop('disabled', true);
					}
				});
			} // checkall
		},

		checkDisablerState: function(input) {
			var $trigger = $(input),
				disable = input.checked,
				formElementsToDisable = $trigger.data('disables'),
				oldValue = $trigger.data('disables-pre-disable-value'),
				clearOnDisable = ($trigger.data('disables-clear') !== undefined);

			$(formElementsToDisable).each(function() {
				var $target = $(this),
					currentValue = $target.val();

				this.disabled = disable;

				if (clearOnDisable && disable) {
					if ($target.is('select.select2')) {
						$target.val(null).trigger('change');
					} else {
						$target.val('');
					}

					$trigger.data('disables-pre-disable-value', currentValue);
				} else if (clearOnDisable && oldValue !== undefined) {
					if ($target.is('select.select2')) {
						$target.val(oldValue).trigger('change');
					} else {
						$target.val(oldValue);
					}
				}
			});
		}
	};

	App.init();

	window.EMSApp = App;
}(
	window.jQuery,
	window.WS, {
		cache: {
			$root: window.jQuery('body')
		}
	}
));
