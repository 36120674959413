(function($, Shepherd, WS, ob_set) {
	'use strict';

	// require WS utility namespace
	if (typeof WS === 'undefined') {
		throw new Error('Whitespace utility namespace does not exist');
	}

	/**
	 */
	var shep = function(settings) {
		var my, cls;

		cls = {
			hasViewedTour: function() {
				return my.hasViewedTour.apply(my, arguments);
			},

			loadTour: function() {
				return my.loadTour.apply(my, arguments);
			}
		};

		my = {
			viewedTours: {},
			key: null, // current tour key
			tour: null, // current shepherd instance

			scenes: {
				'attendee-notifications': [
					{
						'text': '<p>Reminder emails are sent 24 hours before an event begins. If the attendee is showing as pending less than 24 hours before, the email may be missing or incorrect.</p><p>You can edit the attendee record and manually send a reminder email if the email address is incorrectly formatted.</p><p>Reminder SMS messages are sent 24 hours before an event begins if the attendee has opted in.</p>',
						'attachTo': '.tour-reminder-email top',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // attendee-notifications
				'attendee-edit': [
					{
						'text': '<p>Attendee region is set automatically based on the attendee’s postcode, and cannot be updated manually.</p>',
						'attachTo': '[name="address[region_id]"] top',
						'buttons': [{
							'text': 'OK'
						}]
					}
				],
				'the-dashboard': [
					{
						'text': '<h5>Welcome to your new Event Management System.</h5><p>This is your dashboard, which lets you quickly access events, your event bucket and more.</p>',
						'attachTo': '.tour-the-dashboard-step-1 center',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Each time you visit a page for the first time, we will display the tips for that page.</p><p>However, you can show them again for any screen where tips are available by selecting the <i class="fa fa-question-circle"></i> icon.</p>',
						'attachTo': '.tour-the-dashboard-step-2 bottom',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // the-dashboard
				'catalogue-listing': [
					{
						'text': '<p>Use the search & Filter to find event templates by name or region.</p>',
						'attachTo': '.tour-search-step right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>If you need to create a new catalogue event, click here to go to the Catalogue Event Creation screen.</p>',
						'attachTo': '.tour-create-step left',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // catalogue-listing
				'catalogue-select': [
					{
						'text': '<p>Select an event template you’d like to use by clicking the radio button.</p>',
						'attachTo': '.table-responsive tbody tr:first-child td:first-child right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Then select “Create Event from Template”.</p>',
						'attachTo': '.tour-commit-step top',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				],
				'catalogue-create': [
					{
						'text': '<p>A region determines who can view &amp; edit the event template.</p>',
						'attachTo': '.tour-region-step top',
						'buttons': [{
							'text': 'OK'
						}]
					}
				],
				'venue-listing': [
					{
						'text': '<p>Use this search box to find a particular venue.</p>',
						'attachTo': '.tour-search-step right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>All columns in the Venues listing can be used to sort your view. Just click to sort by Name, Address or Region.</p>',
						'attachTo': '.tour-venue-sorting top',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>If you need to create a new Venue, click here to go to the Venue Creation screen.</p>',
						'attachTo': '.tour-create-step left',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // venue-listing
				'event-bucket': [
					{
						'text': '<h5>This is your Event Bucket.</h5><p>You can view and manage events you have added to your bucket, including adding attendees to more than one event, changing event states and deleting events.</p>',
						'attachTo': '.tour-bucket-step-1 center',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>All columns in the Event Bucket can be used to sort your view.</p><p>Just click to sort your events by Name, Start date and time, Venue, Town, Type, State or number of Bookings.</p>',
						'attachTo': '.tour-bucket-step-2 right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>To delete one or more events, select the checkboxes next to the events you wish to delete and use this button.</p><p>This permanently deletes selected events.</p>',
						'attachTo': '.tour-bucket-step-3 right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>To change the state of one or multiple events, select the checkboxes next to the events you wish to change and use this to select the desired state.</p>',
						'attachTo': '.tour-bucket-step-4 right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>You can see the total number of bookings on any event.</p><p>Click the link to go straight to the attendee management screen for the event.</p>',
						'attachTo': '.tour-bucket-step-5 left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Click to remove an event from your event bucket.</p>',
						'attachTo': '.tour-bucket-step-6 left',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // event-bucket
				'event-listing': [
					{
						'text': '<p>To create a new event, just press the Create button.</p>',
						'attachTo': '.tour-create-event-step left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Looking for an event? Search by title here.</p>',
						'attachTo': '.tour-search-step right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Quickly filter your event list by region to look in a specific part of Scotland.</p>',
						'attachTo': '.tour-region-step bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Use these new relative time filters to get a quick view ahead of what\'s coming up.</p>',
						'attachTo': '.tour-filter-step right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>You can quickly see if an event is live, in draft and if you are the person who created it in the State column.</p><p>Click the column to sort it in a different order.</p>',
						'attachTo': '.tour-state-step bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>All columns in the Events listing can be used to sort your view.</p><p>Just click to sort by Type, Name, Start date and time, Venue, Town, Booking numbers or Bucket status.</p>',
						'attachTo': '.tour-sorting-step bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Your event bucket is a place to view events you want to keep tabs on and where you can perform bulk actions like adding an attendee to multiple events.</p>',
						'attachTo': '.tour-bucket-step right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>You can see the total number of bookings on any event. Click the link to go straight to the attendee management screen for the event.</p>',
						'attachTo': '.tour-booked-step bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Simply click the Add button to put an event in your Event Bucket. Click Remove to take it back out.</p>',
						'attachTo': '.tour-bucket-adding-step top',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Just want to look at Live events, or events in draft? Use these filters to quickly change your view.</p>',
						'attachTo': '.tour-states-step right',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // event-listing
				'event-view': [
					{
						'text': '<p>The event\'s state is shown here. Events can only be edited while in draft mode.</p>',
						'attachTo': '.tour-event-view-1 right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>If you wish to edit, duplicate or delete an event, you must move it into Draft state.</p>',
						'attachTo': '.tour-view-Draft bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Events in Pending state are awaiting review and approval by another team member.</p>',
						'attachTo': '.tour-view-Pending bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Move an event into a Rejected state if you want the creator to make edits to it.</p>',
						'attachTo': '.tour-view-Rejected bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Move an event to Live if you are happy for it to be visible on the main website.</p><p>The event will become visible when its live date and time has passed.</p>',
						'attachTo': '.tour-view-Live bottom',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Make an event inactive when you do not wish it to display on the main website.</p>',
						'attachTo': '.tour-view-Inactive left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Duplicating an event makes a carbon copy of the event you are viewing.</p><p>To save your copy, you must make a change to the event information (i.e. date, time, venue and so on)</p>',
						'attachTo': '.tour-view-duplcate left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>This will take you to the edit screen for an event.</p>',
						'attachTo': '.tour-view-update left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>This will permanently delete the event you are viewing.</p>',
						'attachTo': '.tour-view-delete left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>You can see a summary of your event\'s visibility and date/time information here.</p>',
						'attachTo': '.tour-view-datetime center',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>This part of the page shows an overview of your current bookings and waiting list. You can click to manage your attendee list for this event.</p>',
						'attachTo': '.tour-view-bookings left',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Your latest bookings for each event will be displayed here.</p>',
						'attachTo': '.tour-view-latest-bookings top',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				], // event-view
				'event-create-webinars': [
					{
						'text': '<p>To make your webinar live in multiple regions, select from this list.</p>',
						'attachTo': '[data-shepherd-anchor="webinar_regions"] right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Add a link for users to access the event. This will be shared in the confirmation and reminder emails. </p>',
						'attachTo': '[data-shepherd-anchor="webinar_link"] right',
						'buttons': [{
							'text': 'Next'
						}]
					},
					{
						'text': '<p>Add your webinar password, meeting ID, and any other additional information here.</p><p>Attendees will see this information in their confirmation and reminder emails.</p>',
						'attachTo': '[data-shepherd-anchor="webinar_info"] right',
						'buttons': [{
							'text': 'Finish'
						}]
					}
				]
			},

			enable: function() {
				settings = ob_set(settings || {}, shep.defaults);
				my.autoLoadTour();
				my.setup();
			},

			setup: function() {
				var i, el, btn = $(settings.selectors.target);

				// button events
				// Link up buttons with class e.g. a.shepherd and use the
				// data-scene attribute to show the available scene.
				if (btn.is('a') || btn.is('button')) {
					btn.on('click', function() {
						event.preventDefault();
						my.loadTour($(this).data('scene'));
					});
				}

				// shepherd events
				Shepherd.on('complete', function(T) {
					// mark as complete
					my.setTourComplete(my.key);

					// clean up any steps and tethers
					for (i = 0; i < T.tour.steps.length; i += 1) {
						T.tour.steps[i].destroy();
					}

					$(settings.gc.tether).remove();
				});

				my.setTriggerElements();
			},

			/**
			 * Find elements on the page with data-shepherd-trigger set, allowing
			 * the instantiation of tours based on an element trigger instead of page
			 * load. The data attributes available are:
			 *
			 * data-shepherd-trigger="<scene_name>"
			 * 	- Trigger the specific scene on change of the field
			 * data-shepherd-trigger-type="<event>"
			 * 	- If "onchange" is not sufficient, write the required event here
			 *    e.g. focus, click, keyup, etc.
			 * data-shepherd-value="<value>"
			 *  - Specifies the required STRING value of the field before the trigger
			 *    is applied. Note: if the field is a checkbox or radio, the "value"
			 *    attribute is only used when checked.
			 *
			 * Example:
			 * ```
			 *   <!-- input will trigger "mytourscene" tour when checked for the first time -->
			 *   <input name="field" value="1"
			 *     data-shepherd-trigger="mytourscene"
			 *     data-shepherd-value="1">
			 * ```
			 */
			setTriggerElements: function() {
				// Find all trigger elements
				$('[data-shepherd-trigger]').each(function() {
					var $element = $(this),
						triggerType = $element.data('shepherd-trigger-type') || 'change',
						requiredValue = $element.data('shepherd-value').toString();

					$element.bind(triggerType, function(event) {
						my.elementTrigger($element, requiredValue);
					});
				});
			},

			elementTrigger: function($element, requiredValue) {
				var value,
					scene = $element.data('shepherd-trigger');

				if (typeof requiredValue !== 'undefined') {
					// get input value
					switch ($element.get(0).type) {
						case 'checkbox':
						case 'radio':
							value = ($element.get(0).checked ? $element.val() : '').toString();
							break;

						default:
							value = $element.val().toString();
					}
				}

				if (typeof requiredValue === 'undefined' || value === requiredValue) {
					// requiredValue is satisfied
					my.hasViewedTour(scene, function(viewed) {
						!viewed && my.loadTour(scene);
					})
				} else {
					// requiredValue is no longer satisfied
					my.unLoadTour(scene);
				}
			},

			autoLoadTour: function() {
				// get the tour key
				var key = $('body').data('shepherd');

				// if we have a scene that matches the key, check
				// for the shepherd cookie, if we don't have it,
				// play the scene.
				if (typeof my.scenes[key] !== 'undefined') {
					my.hasViewedTour(key, function(viewed) {
						if (!viewed) {
							my.loadTour(key);
						}
					});
				}
			},

			loadTour: function(name) {
				my.key = name;

				// Prevent tour from loading more than once
				if (my.tour && my.tour.__ems_tour_name === name) {
					return;
				}

				// create a new tour
				my.tour = new Shepherd.Tour({
					'defaults': {
						'classes': 'shepherd-theme-dark',
						'scrollTo': false
					}
				});

				my.tour.__ems_tour_name = name;

				// add the scenes to the tour
				$.each(my.scenes[my.key], function(index, step) {
					// add a button action
					step.buttons[0].action = my.tour.next;

					// only show the step if the dom node exists
					step.showOn = function() {
						// fast
						return document.querySelector(step.attachTo.split(" ")[0]) ? true : false;
					};

					my.tour.addStep(index, step);
				});

				my.tour.start();
			},

			unLoadTour: function(name) {
				if (
					my.tour &&
					(typeof name === 'undefined' || my.tour.__ems_tour_name === name)) {
					my.tour.cancel();
					my.tour = null;
				}
			},

			setTourComplete: function(name, callback) {
				my.viewedTours[name] = true;
				my.tour = null;

				$.ajax({
					'method': 'PUT',
					'url': '/tour/complete/' + name
				})
				// successful response
				.done(function(data) {
					if (callback && typeof callback === 'function') {
						callback.apply(this, [data.message]);
					} else {
						return data.message;
					}
				});
			},

			hasViewedTour: function(name, callback) {
				if (typeof my.viewedTours[name] !== 'undefined') {
					// Use cached response if it's set
					return callback.apply(this, [my.viewedTours[name]]);
				}

				$.ajax({
					'method': 'GET',
					'url': '/tour/viewed/' + name
				})
				// successful response
				.done(function(data) {
					if (callback && typeof callback === 'function') {
						if (typeof data.message !== 'undefined') {
							// Store response in cache
							my.viewedTours[name] = data.message;
						}

						callback.apply(this, [data.message]);
					} else {
						return data.message;
					}
				});
			}
		}

		my.enable(settings);

		return cls;
	};

	/**
	 * The default WS.shep settings
	 * @typedef WS.shep.defaults
	 * @property {object} selectors - Default selector settings.
	 */
	shep.defaults = {
		'selectors': {
			'target': 'a.shepherd'
		},
		'gc': {
			'tether': 'div[data-tether-id]'
		}
	};

	$.WS.createModule('shep', shep);

	window.WS.shep = shep;
}(
	window.jQuery,
	window.Shepherd,
	window.WS,
	window.ob_set
));
