(function($) {
	'use strict';

	var o = $({});

	/**
	 * $.subscribe
	 *
	 */
	$.subscribe = function() {
		o.on.apply(o, arguments);
	};

	/**
	 * $.unsubscribe
	 *
	 */
	$.unsubscribe = function() {
		o.off.apply(o, arguments);
	};

	/**
	 * $.publish
	 *
	 */
	$.publish = function() {
		o.trigger.apply(o, arguments);
	};

}(window.jQuery));