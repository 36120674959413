/**
 * An HTML DOM element.
 * @typedef {object} HTMLElement
 */

/**
 * The built in window Event object.
 * @typedef {object} Event
 */

/**
 * The global jQuery object.
 * @typedef {object} jQuery
 * @see http://api.jquery.com/jQuery/
 */

/**
 * The global jQuery Event object.
 * @typedef {object} jQueryEvent
 * @memberof jQuery
 * @see http://api.jquery.com/category/events/event-object/
 */

/**
 * The global jQuery Deferred object.
 * @typedef {object} jQueryDeferred
 * @see http://api.jquery.com/category/deferred-object/
 */

/**
 * The global Promise object.
 * @typedef {object} Promise
 * @see https://promisesaplus.com/
 */

/**
 * @typedef {object} WSFormRequirement
 * @property {boolean} required - This field is required.
 */

/**
 * @namespace WS
 */
(function($, WS, ob_set, undefined) {
	"use strict";

	var Form;

	// require WS utility namespace
	if (typeof WS === "undefined") {
		throw new Error("Whitespace utility namespace does not exist");
	}

	$.fn.formOffset = function() {
		// data the offset from element to the Form
		var element, pos;

		pos = [];

		if (!(element = $(this))) {
			// element can't be retrieved - return false
			return false;
		}

		if (element[0].tagName === "FORM") {
			// element is the form - return base offset
			return {
				'left': 0,
				'top': 0
			};
		}

		pos = {
			'left': element[0].offsetLeft,
			'top': element[0].offsetTop
		};

		while ((element = element.offsetParent()).length > 0) {
			if (element[0].tagName === "FORM" || element[0].tagName === "HTML") {
				// break if form tag (or for some reason the HTML tag) is found
				break;
			}

			pos.left += element[0].offsetLeft;
			pos.top += element[0].offsetTop;
		}

		return pos;
	};

	/**
	 * @class WS.Form
	 * @version v1.0.1-beta
	 * @param {jQuery} $form - Form element to manage
	 * @param {WS.Form.defaults} settings - WS.Form settings - inherits from {@link WS.Form.defaults}
	 * @example
	 * // jQuery instantiation
	 * $("Form").WS("Form");
	 *
	 * // vanilla instantiation
	 * Form = WS.Form($("#myForm"));
	 */
	Form = function($form, settings) {
		var cls = this, my;

		if (!(this instanceof Form)) {
			throw new Error("Form must be instaniated with the `new` prefix");
		}

		this.identify = function() {
			return my.identify.apply(cls, arguments);
		};

		this.submit = function() {
			return my.submit.apply(cls, arguments);
		};

		this.validate = function() {
			return my.validate.apply(cls, arguments);
		};

		this.validateField = function() {
			return my.validateField.apply(cls, arguments);
		};

		this.fieldValues = function() {
			return my.fieldValues.apply(cls, arguments);
		};

		this.addFields = function() {
			return my.addFields.apply(cls, arguments);
		};

		this.addField = function() {
			return my.addField.apply(cls, [arguments[0], arguments[1], true]);
		};

		this.getFields = function() {
			return my.getFields.apply(this, arguments);
		};

		this.getFieldDefinition = function() {
			return my.getFieldDefinition.apply(this, arguments);
		}

		this.removeField = function() {
			my.removeField.apply(cls, arguments);
			return $form;
		};

		/**
		 * A built-in instance of {@link WS.Form.Popup}, pre-prepared for this Form.
		 * @function WS.Form#popup
		 * @param {String} method - Method name to invoke.
		 * @param {*} [...] - This and subsequent arguments are passed to the invoked method.
		 */
		this.popup = function() {
			if (my.popup) {
				return my.popup[arguments[0]].apply(cls, Array.prototype.slice.call(arguments, 1));
			}
		};

		my = {
			status: {
				// current Form value(s)
				snapshot: null,
				// parsed HTMLElement fields
				parsed: []
			},

			// object containing the field/requirement definitions
			fields: {},

			enable: function() {
				$form = $($form);

				if ($form.length > 1) {
					throw new Error("Only one Form DOM element should be passed to WS.Form.");
				}
				if ($form.data("ws-Form")) {
					throw new Error("WS.Form has already been applied to this Form.");
				}

				settings = ob_set(settings || {}, Form.defaults);

				my.runTests();

				my.parseUI(function() {
					my.popup = WS.Form.Popup($form, settings.popup);

					my.status.enabled = true;

					// add all current fields to the internal set
					my.addFields();

					my.utils.log("WS.Form instance enabled for Form", $form);
				});

				/**
				 * @member {jQuery} WS.Form#$form
				 * @description
				 * jQuery object containing the applicable `Form` HTMLElement.
				 *
				 * ##### Fires the following events:
				 * * {@link WS.Form~wsform:success}
				 * * {@link WS.Form~wsform:fail}
				 */
				cls.$form = $form;
			},

			/**
			 * Test for various browser comaptibilities
			 * @function WS.Form.cls#runTests
			 * @private
			 */
			runTests: function() {
				var element;

				my.tests = {};

				// test for HTML5 attributes
				element = document.createElement("input");

				my.tests.require = "required" in element;
			},

			/**
			 * Identifies the Form and applies a numeric ID if necessary.
			 * @function WS.Form#identify
			 * @returns {Mixed} `false` if the form could not be identified, `null` if the form
			 * has an `id` property but is not string-based, or the name of the identified form.
			 */
			identify: function() {
				// validate/sanitise 'submit' method
				if (typeof $form[0].submit === "undefined" ||
					typeof $form[0].submit.call === "undefined") {
					my.utils.error(
						"Error with Form submit method. Possible collision with element named 'submit'",
						$form
					);

					return false;
				}

				// validate/sanitise 'id' attribute
				if (typeof $form[0].id === "undefined" || !$form[0].id) {
					// Form id is either broken or not set
					$("form").each(function(index) {
						var $this = $(this);

						if ($this.is($form)) {
							$this[0].id = "anonymous-form-" + (index + 1);
							return false;
						}
					});
				}

				if (typeof $form[0].id === "string") {
					return $form[0].id;
				} else {
					my.utils.warn(
						"Error retrieving Form ID. Possible collision with element named 'id'",
						$form
					);

					return null;
				}

				return true;
			},

			/**
			 * Parses the Form interface and applies necessary JS to the elements
			 * @function WS.Form#parseUI
			 * @private
			 */
			parseUI: function(after) {
				if (my.identify()) {
					// turn native Form validation off
					$form.attr("novalidate", "novalidate");

					// set up Form styles
					$form
						.addClass(settings.classes.enabled)
						.css(settings.styles.Form);

					// bind events
					my.bindEvents(after);
				} else {
					my.utils.error("Unrecoverable errors with WS.Form have halted instantiation.", $form);
				}
			},

			/**
			 * Binds the general event handlers
			 * @function WS.Form#bindEvents
			 * @private
			 */
			bindEvents: function(after) {
				$form
					.bind("change", my.eventRegister("Form_change"))
					.on("focus blur", settings.selectors.fields, my.eventRegister("focus_state"));

				if (typeof after === "function") {
					after.apply(cls);
				}
			},

			/**
			 * Registers an event handler by ID for use within Ws.Form#event
			 * @function WS.Form#eventRegister
			 * @private
			 */
			eventRegister: function(id) {
				return function(event) {
					my.event.apply(this, [event, id]);
				};
			},

			/**
			 * Generic event handler
			 * @function WS.Form#event
			 * @private
			 */
			event: function(event, id) {
				if (!my.status.enabled) {
					return false;
				}

				switch (id) {
					case "Form_change":
						// the Form contents have changed
						my.update();
						break;

					case "focus_state":
						// focus state has changed
						my.ui.focus(my.utils.findField(this), event.type, this);
						break;
				}
			},

			/**
			 * Form update handler - runs whenever the Form data has changed
			 * @function WS.Form#update
			 * @private
			 */
			update: function() {
				if (!my.status.enabled) {
					return false;
				}

				my.status.snapshot = $form.serializeObject();
			},

			/**
			 * @function WS.Form#submit
			 * @description
			 * Helper function for the Form submission method.
			 */
			submit: function() {
				var validation;

				if (!my.status.enabled) {
					return false;
				}

				return my.validate()
					.then(function(validation) {
						if (validation.valid) {
							// validation succeeded
							return Promise().resolve(validation);

							if (!Form.events.success.isDefaultPrevented()) {
								// all validated, success event not prevented - submit Form
								$form[0].submit();
							}
						} else {
							return Promise.reject("Validation failed");
						}
					});
			},

			/**
			 * Validates all fields.
			 * @function WS.Form#validate
			 * @returns {Promise} Object containing {@link WS.Form.ValidateResults} Form.
			 * validation results. Only fields successfully registered within WS.Form will be
			 * returned within the result data.
			 * @example
			 * // simple submission on validation
			 * wsform.validate()
			 * 	.then(function(results) {
			 *		if (results.valid) {
			 * 			// form is valid
			 * 			form.submit();
			 * 		}
			 *	});
			 * @example
			 * // handling validation errors
			 * wsform.validate()
			 * 	.then(function(validation) {
			 * 		if (validation.valid) {
			 * 			// submit the form
			 * 			$this.get(0).submit();
			 * 		} else {
			 * 			// form isn't valid - produce a popup
			 * 			$("#form-popup").empty().show();
			 *
			 * 			// add form error markup to popup
			 * 			$("#form-popup").append(
			 * 				wsform.popup("generateMarkup", [
			 * 					WS.Form.tip("Validation for this form failed")
			 * 				])
			 * 			);
			 *
			 * 			// position popup
			 * 			wsform.popup(
			 * 				"computePosition",
			 * 				$("#form-popup"),
			 * 				null, // (used for producing the popup at the 'submit' button)
			 * 				function(css) {
			 * 					// called once the position is computed
			 * 					$popup.css(css);
			 * 				}
			 * 			);
			 * 		}
			 * 	});
			 */
			validate: function() {
				var name, valid, results;

				if (!my.status.enabled) {
					return false;
				}

				// assume the Form is valid up-front
				valid = true;
				results = [];

				// loop through each field and validate individually
				for (name in my.fields) {
					if (my.fields.hasOwnProperty(name) && my.fields[name].require) {
						results.push(my.validateField(name));
					} else {
						my.utils.log("Field '" + name + "' has no requirements - skipping");
					}
				}

				// returns a promise object
				return Promise.all(results)
					.then(function(results) {
						var a, validation = [];

						for (a = 0; a < results.length; a++) {
							if (!results[a].valid) {
								// any non-valid field will throw global valid into false
								valid = false;
							}

							if (results[a] instanceof Form.Result) {
								validation.push(results[a]);
							}
						}

						if (valid) {
							$form.trigger(Form.events.success);
						} else {
							// validation failed
							$form.trigger(Form.events.fail);
						}

						return new Form.ValidateResults(valid, validation);
					});
			},

			/**
			 * Validates a single field.
			 * @function WS.Form#validateField
			 * @param {string} name - Field name to validate.
			 * @returns {Promise} Object containing either a single Form validation
			 * {@link WS.Form.Result}, or `true` if the field has no validation specification.
			 * The Promise Object will reject if the field doesn't exist within WS.Form.
			 * @example
			 * wsForm.validateField()
			 * 	.then(function(result) {
			 *		if (result.valid) {
			 * 			// field is valid
			 * 		}
			 *	});
			 */
			validateField: function(name) {
				var field, field_value, vcls,
					tasks = [];

				if (!my.status.enabled) {
					return false;
				}

				if (typeof name !== "string") {
					throw new TypeError("field argument must be a string.");
				}

				if ((field = my.fields[name])) {
					if (field.require) {
						// get latest field values
						field_value = my.fieldValues(name);

						for (vcls in field.require) {
							// look through the require keys, checking for a validator
							if (WS.Form.validators[vcls] && WS.Form.validators[vcls].test) {
								// run existsing validator on the field value
								tasks.push({
									id: vcls,
									fn: WS.Form.validators[vcls].test.apply(
										this, [
											field_value[name],
											field.require[vcls]
										]
									)
								});
							}
						}

						return Promise.all($.map(tasks, function(item) {
							return item.fn;
						}))
							.then(function(values) {
								var a, results, result;

								// start off results with the requirement skeleton
								results = $.extend({}, Form.requirement);

								for (a = 0; a < values.length; a += 1) {
									if (tasks.length > a) {
										results[tasks[a].id] = !!values[a];
									}
								}

								field.result = new Form.Result(name, field.require, results);

								if (field.result.valid === false) {
									my.utils.warn("Field '" + name + "' failed validation");
								} else {
									my.utils.log("Field '" + name + "' passed validation");
								}

								return field.result;
							}, function() {
								my.utils.error("Validation task promises failed for field '"  + name + "'");

								$(arguments).each(function() {
									my.utils.error(this);
								});
							});
					} else {
						my.utils.log("Field '" + name + "' has no requirements");
						return Promise.resolve(true);
					}
				} else {
					my.utils.warn(my.utils.string('field_not_defined', name));
					return Promise.reject(new Error(my.utils.string('field_not_defined', name)));
				}
			},

			/**
			 * Gets the value of a field or all fields.
			 * @function WS.Form#fieldValues
			 * @param {...String} name
			 * @returns {object} Field value(s)
			 */
			fieldValues: function() {
				var values = {},
					names = Array.prototype.slice.call(arguments, 0);

				if (!my.status.enabled) {
					return false;
				}

				if (!names.length) {
					names = my.utils.getFieldKeys();
					my.update();
				} else if (my.status.snapshot === null || names.length > 1) {
					my.update();
				}

				$(names).each(function() {
					if (my.status.snapshot !== null && this in my.status.snapshot) {
						if ($.isArray(my.status.snapshot[this])) {
							values[this] = my.status.snapshot[this];
						} else {
							values[this] = [my.status.snapshot[this]];
						}
					} else if (this in my.fields) {
						values[this] = [''];
					}
				});

				return values;
			},

			/**
			 * Adds multiple fields to the validation list.
			 * @function WS.Form#addFields
			 * @param {WS.Form.fields[]} fields - An Array containing field definitions for this Form.
			 * @returns {Number} Number of fields added. 0 if no fields were added.
			 * @example
			 * // adding multiple fields
			 * wsform.addFields({
			 * 	'simple_text': {
			 * 		'require': {
			 * 			'async_notempty': true
			 * 		}
			 * 	},
			 * 	'simple_email': {
			 * 		'require': {
			 * 			'async_notempty': true,
			 * 			'email': true
			 * 		},
			 * 		'popup': {
			 * 			'alignment': WS.Form.Popup.alignments.after
			 * 		}
			 * 	},
			 * 	'one_radio': '2',
			 * 	'one_check_1[]': ['1', '2'],
			 * });
			 */
			addFields: function(fields) {
				var field, added;

				if (!my.status.enabled) {
					return false;
				}

				added = 0;

				// name is not defined - populate fields array with all field names
				if (fields === undefined) {
					fields = my.utils.getFieldNames();
				}

				// add fields found by name
				for (field in fields) {
					if (fields.hasOwnProperty(field)) {
						if (my.addField(field, fields[field]) !== false) {
							added += 1;
						}
					}
				}

				// parse field data set
				my.parseFields(my.fields);

				return added;
			},

			/**
			 * Adds a field to the validation list.
			 * @function WS.Form#addField
			 * @param {string} name - Name of the field as set in the Form.
			 * @param {WS.Form.field} definition - Field definition for this Form field.
			 * @returns {Mixed} Resulting {@link WS.Form.field} field definition, internally
			 * normalised, or `false` if the addition failed.
			 * @example
			 * // adding a single field
			 * instances.one.addField("simple_area", {
			 * 	'require': {
			 * 		'max_length': 100
			 * 	}
			 * });
			 */
			addField: function(name, definition, parse_after) {
				if (!my.status.enabled) {
					return false;
				}

				if (typeof name !== "string") {
					throw new TypeError("name argument must be a string.");
				}

				if (definition === undefined) {
					definition = false;
				}

				// normalise definition 'require' object
				if (typeof definition !== "object" || $.isArray(definition)) {
					// definition is not a basic object
					definition = {
						'require': {
							'value': definition
						}
					};
				} else if (typeof definition === "object" && "require" in definition &&
					(typeof definition.require !== "object" || $.isArray(definition.require))) {
					// definition.require exists but its value isn't an object
					definition.require = {
						'value': definition.require
					};
				}

				// at this point, the definition is an object - add extra properties
				definition.name = name;

				if (!(definition.$fields = $form.find("[name='" + name + "']")).length) {
					// field not found within form
					my.utils.error("Form field(s) named '" + name + "' cannot be found!");
					return false;
				}

				if (definition.$fields.length) {
					definition.$labels = my.utils.findFieldLabels(definition.$fields);

					if (definition.$labels.length < definition.$fields.length &&
						definition.$fields.attr("type") !== "hidden") {
						// number of labels doesn't match number of fields
						my.utils.warn(
							"Unique label(s) for element(s) '" + name + "' not found. This may cause accessibility issues",
							definition.$fields
						);
					}
				}

				my.fields[name] = definition;

				if (parse_after === true) {
					// parse field data set
					my.parseFields(my.fields);
				}

				return definition;
			},

			/**
			 * Removes a field's customisations from the validation list.
			 * @function WS.Form#removeField
			 * @returns {jQuery} Containing form DOM element.
			 */
			removeField: function(name) {
				if (!my.status.enabled) {
					return false;
				}

				if (name in my.fields) {
					// re-add without a definition
					my.addField(name);
				} else {
					my.utils.warn("Field '" + name + "' not registered with WS.Form");
				}
			},

			/**
			 * Returns the current field definition set as an iterable object.
			 * @function WS.Form#getFields
			 * @returns {WS.Form.fields} Object of field definitions.
			 */
			getFields: function() {
				var field, fields,
					length = 0;

				// get number of fields
				for (field in my.fields) {
					length += 1;
				};

				fields = function() {
					var index = 0;

					for (field in my.fields) {
						this[field] = my.fields[field];
						this[index++] = my.fields[field];
					};
				};

				fields.prototype = new function() {
					this.length = length;
				};

				return new fields();
			},

			/**
			 * Returns a single field's definition.
			 * @function WS.Form#getFieldDefinition
			 * @arg {String} name - Field name as registered.
			 * @returns {WS.Form.Field} field definition or `null` if the field isn't registered.
			 */
			getFieldDefinition: function(name) {
				if (typeof name === "string") {
					return my.fields[name] || null;
				} else {
					throw new TypeError("name argument must be a string.");
				}
			},

			/**
			 * Parses an array of field definitions.
			 * @function WS.Form#parseFields
			 * @private
			 */
			parseFields: function(fields) {
				var field;

				for (field in fields) {
					// run all parsing functions - they must return the indivudal definition
					if ($.inArray(field, my.status.parsed) === -1) {
						// sample field parser function
						//field = my.utils.parserFunction(fields[field]);

						my.status.parsed.push(field);
					}
				}
			},

			utils: {
				log: function(message, $context) {
					if (typeof console !== "undefined" && console.info) {
						if ($context) {
							console.info(message + "\n", my.utils.contextSignature($context));
						} else {
							console.info(message);
						}
					}
				},

				warn: function(message, $context) {
					if (typeof console !== "undefined" && console.warn) {
						if ($context) {
							console.warn(message + "\n", my.utils.contextSignature($context));
						} else {
							console.warn(message);
						}
					}
				},

				error: function(message, $context) {
					if (typeof console !== "undefined" && console.error) {
						if ($context) {
							console.error(message + "\n", my.utils.contextSignature($context));
						} else {
							console.error(message);
						}
					}
				},

				contextSignature: function($context) {
					var signature = [];

					$context.each(function() {
						var item = [this.tagName];

						if (this.id) {
							item.push("#" + this.id);
						}

						if (this.className) {
							item.push("." + this.className.split(" ").join("."));
						}

						signature.push(item.join(""));
					});

					if (signature.length === 1) {
						return signature[0];
					} else {
						return signature;
					}
				},

				getFieldNames: function() {
					var fields = {};

					$form.find(settings.selectors.fields).each(function() {
						if (this.name && !fields[this.name]) {
							fields[this.name] = {};
						}
					});

					return fields;
				},

				getFieldKeys: function() {
					var name,
						result = [];

					for (name in my.fields) {
						if (my.fields.hasOwnProperty(name)) {
							result.push(name);
						}
					}

					return result;
				},

				findField: function(field) {
					if (typeof field === "object") {
						if (field instanceof $ && field.length) {
							field = field.first().attr("name");
						} else if (field.tagName && field.name) {
							field = field.name;
						}
					}

					if (typeof field === "string") {
						return my.fields[field] || null;
					}
				},

				findFieldLabels: function($fields) {
					var $labels = $();

					$fields.each(function() {
						var $label, $field = $(this);

						if (($label = $form.find("label[for='" + ($field.attr("name") || "") + "']")).length === 1) {
							$labels = $labels.add($label);
						}

						if (($label = $form.find("label[for='" + ($field.attr("id") || "") + "']")).length === 1) {
							$labels = $labels.add($label);
						}

						if (($label = $field.eq(0).closest("label:not([name])")).length === 1) {
							$labels = $labels.add($label);
						}
					});

					return $labels;
				},

				/**
				 * Example function for parsing a field definition and returns it
				 * @function WS.Form#parserFunction
				 * @param {WS.Form.field} field - Field definition.
				 * @private
				 */
				parserFunction: function(field) {
					return field;
				},

				string: function(id) {
					var string, a;

					if ((string = settings.strings[id])) {
						for (a = 1; a < arguments.length; a += 1) {
							string = string.replace(new RegExp("\\\$" + a), arguments[a]);
						}
					}

					return string;
				}
			},

			ui: {
				/**
				 * Focus/blur a Form element
				 * @function WS.Form#ui.focus
				 * @private
				 */
				focus: function(field, type, element) {
					if (!field) {
						return;
					}

					if (type === "focus" || type === "focusin") {
						field.tips = [];

						$(element).trigger($.Event(Form.events.focus), [field, cls]);
					} else {
						// field being blurred
						$(element).trigger($.Event(Form.events.blur), [field, cls]);
					}
				}
			}
		};

		my.enable();
	};

	/**
	 * @typedef {Object} WS.Form.requirement
	 * @property {WS.Form.validators.value} value
	 * @property {WS.Form.validators.min_length} min_length
	 * @property {WS.Form.validators.max_length} max_length
	 * @property {WS.Form.validators.email} email
	 * @description
	 * An object containing `key`:`value` pairs using the built-in {@link WS.Form.validators} set.
	 * The `value` of each property is passed to the relevant validator `test` function as the
	 * __required__ value, along with the value of the field.
	 *
	 * Individual validator `test` functions contain more inFormation on their possible requirement
	 * values and how to use them.
	 * @example <caption>Single requirement item:</caption>
	 * {
	 * 	'value': true,		// field must contain a value
	 * 	'min_length': 3		// minimum length of 3 characters
	 * }
	 */
	Form.requirement = {};

	/**
	 * @typedef {Object} WS.Form.fields
	 * @property {Number} length - Fieldset length
	 * @description
	 * A standard field definition set. Each object item should be a `key`:`value` pair of the
	 * field's name and a {@link WS.Form.field} object.
	 * Fields in this object can be referenced by their name, or by an index. Combine this with
	 * the `length` property to iterate through fields in the inserted order.
	 */
	Form.fields = {};

	/**
	 * @typedef {mixed} WS.Form.field
	 * @property {boolean|WS.Form.requirement} [require] - Either a boolean `true` or `false`,
	 * string value of the required value, array of the required value(s), or a
	 * {@link WS.Form.requirement} object.
	 * @property {object} [cascade] - Cascading requirements:
	 * @property {mixed} cascade.value - Required value(s) of the field before cascading occurs.
	 * @property {WS.Form.Requirement[]} cascade.fields - Array of requirements to cascade.
	 * @property {object} popup - Popup settings.
	 * @property {WS.Form.Popup.alignments} popup.alignment - Popup alignment.
	 * @property {jQuery} $fields - (Read only) Field(s) matching the name defined for this field name.
	 * @property {jQuery} $labels - (Read only) Field label(s) matching the defined field(s).
	 * @property {WS.Form.tip[]} tips - (Read only) Recommended popup tips for this field during interaction.
	 * @property {WS.Form.result} result - (Read only) Result object (populated after a validation).
	 * @description
	 * A single field definition. The definition should be either a basic object with the described
	 * properties, or a boolean, string or array, which will convert to the `require.value` requirement.
	 * @example <caption>Implied object when using 'foo' as a value:</caption>
	 * field = 'foo';
	 *
	 * // ... Converts to:
	 * field = {
	 * 	'require': {
	 * 		'value': 'foo'
	 * 	}
	 * };
	 * @example <caption>Implied object when using an array as a value for `require`:</caption>
	 * field = {
	 * 	'require': ['bar', 'baz']
	 * };
	 *
	 * // ... Converts to:
	 * field = {
	 * 	'require': {
	 * 		'value': ['bar', 'baz']
	 * 	}
	 * };
	 */
	Form.field = {
		'require': $.extend({}, Form.requirement),
		'cascade': null
	};

	/**
	 * @class WS.Form.ValidateResults
	 * @param {boolean} valid - Whether the overall results are valid or not.
	 * @param {WS.Form.Result[]} results - results array.
	 * @property {WS.Form.Result[]} results - array of results as passed.
	 * @property {bool} valid - Either `true` or `false` depending on whether the results were all
	 * considered valid.
	 */
	Form.ValidateResults = function(valid, results) {
		this.valid = valid; // validation result for the entire Form
		this.results = results; // WS.Form.Result array

		return this;
	};

	/**
	 * @class WS.Form.Result
	 * @param {string} name - Name of the field.
	 * @param {WS.Form.requirement} require - Standard requirement object.
	 * @param {object} result - A `key`:`value` pair containing computed booleans
	 * depending on whether each requirement was satisfied. The `key` of each item should match
	 * those within the `require` argument.
	 * @property {string} name - Name of the {@link HTMLElement} or group of elements representing
	 * the field.
	 * @property {Boolean} valid - `true` if the result is valid, `false` otherwise.
	 * @property {WS.Form.requirement} require - Standard requirement object, as originally defined
	 * in the field requirements.
	 * @property {Object} result - Results of each requirement, as named.
	 * @description
	 * A standard validation result from WS.Form. Generated during validation and both returned
	 * via the Promise object returned as well as being set against the definition for each form
	 * element.
	 */
	Form.Result = function(name, require, results) {
		var key, result = {}, valid;

		// assume field is valid up-front
		valid = true;

		if (typeof require === "object" &&
			typeof results === "object") {
			// loop through requirements object and check against results
			for (key in require) {
				if (key in results) {
					if (results[key] === true || results[key] === false) {
						// result was made - set true or false
						result[key] = results[key];

						if (!results[key]) {
							valid = false;
						}
					} else {
						results[key] = null;
					}
				} else {
					results[key] = null;
				}
			}
		}

		this.name = name;
		this.valid = valid;
		this.result = result;
		this.require = require;

		return this;
	};

	/**
	 * A standard tip from WS.Form.
	 * @function WS.Form.tip
	 * @param {string} message - Message content for the tip.
	 * @param {WS.Form.tipTypes} type - Type of the tip.
	 * @property {string} message - Message content for the tip.
	 * @property {WS.Form.tipTypes} type - Type of the tip.
	 */
	Form.tip = function(message, type) {
		return {
			'message': message,
			'type': type || WS.Form.tipTypes.message
		};
	};

	/**
	 * The default WS.Form settings
	 * @typedef WS.Form.defaults
	 * @property {object} classes - Default class name settings.
	 * @property {string} classes.enabled
	 * @property {object} styles
	 * @property {WS.Form.Popup.Settings} popup
	 */
	Form.defaults = {
		'selectors': {
			'fields': [
				'input:not([type = \'submit\']):not([type = \'button\'])',
				'select',
				'textarea',
				'button'
			].join(", ")
		},
		'classes': {
			'enabled': 'ws-Form-enabled'
		},
		'styles': {
			'Form': {
				'position': 'relative'
			}
		},
		'strings': {
			'field_not_defined': 'Field "$1" is not defined within WS.Form.'
		},
		'popup': {}
	};

	Form.events = {
		/**
	     * Form validation success event.
		 * @event WS.Form~wsform:success
		 * @type {jQueryEvent}
		 * @this WS.Form#$form
		*/
		'success': $.Event("wsform:success"),

		/**
	     * Form validation failure event.
		 * @event WS.Form~wsform:fail
		 * @type {jQueryEvent}
		 * @this WS.Form#$form
		*/
		'fail': $.Event("wsform:fail"),

		/**
	     * Form field focus event. Fired on any Form field when focused.
		 * @event WS.Form~wsform:focus
		 * @type {jQueryEvent}
		 * @param {jQueryEvent} event - jQuery Event object.
		 * @param {WS.Form.field} field - Field definition for this Form element.
		 * @param {WS.Form} cls - Instance of WS.Form, as applied to the Form HTMLElement.
		 * @this HTMLElement
		 * @example
		 * // producing a message with the wsform:focus event
		 * $("form").on("wsform:focus wsform:blur", "input, textarea", function(event, field, cls) {
		 * 	var requirement, tips, data;
		 *
		 * 	if (event.type === "wsform:focus") {
		 * 		// get current (if any) tips set against element
		 * 		tips = field.tips;
		 *
		 * 		// if the field has a current WS.Form.Result, parse it for tip data
		 * 		if (field.result) {
		 * 			for (requirement in field.result.result) {
		 * 				if (!field.result.result[requirement]) {
		 * 					// requirement was not fulfilled - set message based on validation type
		 * 					switch (requirement) {
		 * 						case "value":
		 * 							tips.push(WS.Form.tip(
		 * 								"Please fill in this field",
		 * 								WS.Form.tipTypes.error
		 * 							));
		 * 							break;
		 *
		 * 						case "min_length":
		 * 							data = field.result.require.min_length;
		 *
		 * 							tips.push(WS.Form.tip(
		 * 								"Minimum " + data + " chars required",
		 * 								WS.Form.tipTypes.error
		 * 							));
		 * 							break;
		 * 					}
		 * 				}
		 * 			}
		 * 		}
		 *
		 * 		if (tips.length) {
		 * 			// display a message
		 * 			$("#message").empty().show().append(
		 * 				// add generated markup from tip data
		 * 				cls.popup("generateMarkup", tips)
		 * 			);
		 * 		}
		 * 	} else {
		 * 		// hide the message
		 * 		$("#message").hide();
		 * 	}
		 * });
		 */
		'focus': 'wsform:focus',

		/**
		 * Form field blur event. Fired on any Form field when blurred (lost focus).
		 * @event WS.Form~wsform:blur
		 * @type {jQueryEvent}
		 * @param {jQueryEvent} event - jQuery Event object.
		 * @param {WS.Form.field} field - Field definition for this Form element.
		 * @param {WS.Form} cls - Instance of WS.Form, as applied to the Form HTMLElement.
		 * @this HTMLElement
		 */
		'blur': 'wsform:blur'
	};

	/**
	 * A validator specification.
	 * @function WS.Form.validator
	 * @param {Array} value - The value of the Form element.
	 * @param {Mixed} required - The requirement spec for this validation, as defined by
	 * {@link WS.Form.requirement}.
	 */
	 Form.validator = function(value, required) {
	 	return (function() {}).apply(Form, [value, required]);
	 };

	/**
	 * @namespace validators
	 * @memberof WS.Form
	 * @description
	 * Validators are functions that, given a `value` value and a `require` requirement, return a
	 * boolean depending on whether the requirement has been satisfied.
	 *
	 * Promises are also supported (see {@link jQueryDeferred}). When returning a promise from a validator,
	 * WS.Form will wait for its `resolve` or `reject` state.
	 *
	 * Individual validators are documented with regards to the requirement values they take, but
	 * all validators are internally passed the value of the Form field as either a string or an
	 * array of strings, depending on the type of field and the value(s) chosen.
	 *
	 * Validators are sent two arguments, as defined by {@link WS.Form.validator}.
	 * @example
	 * // a sample basic validator
	 * WS.Form.validators.binaryonly = {
	 * 	'test': function(value, required) {
	 * 		return !!(value[0].match(/^[01]*$/));
	 * 	}
	 * };
	 * @example
	 * // a sample asynchronous validator, returning a promise
	 * WS.Form.validators.async_notempty = {
	 * 	'test': function(value, required) {
	 * 		// simulating an async network request that checks the value isn't empty
	 * 		return $.ajax("async_vadlidate.php", {
	 * 			'data': {
	 * 				'value': value[0]
	 * 			}
	 * 		}).then(function(response) {
	 * 			return response.valid;
	 * 		}, function(xhr) {
	 * 			return xhr.statusText;
	 * 		});
	 * 	}
	 * };
	 */
	Form.validators = {
		/**
		 * Validates the existence of a field's value.
		 * @namespace WS.Form.validators.value
		 */
		'value': {
			/**
			 * @function WS.Form.validators.value.test
			 * @param {mixed} value - Field value.
			 * @param {mixed} required - `true`, `false` an array or string depending on whether the field
			 * is required to contain a value.
			 * @returns {boolean} `true` if the validation succeeds, `false` otherwise.
			 * @description
			 * ##### Possible values for the `required` argument:
			 * * `true` or `false` - Set boolean values for ensuring the field contains **any** value,
			 *   regardless of the value inputted, or in the case of `false`, contains **no** value.
			 * * `array` - In the case of fields that can contain multiple values, ensure that **all** of the
			 *   defined values are set.
			 * * `string` - The field's value must exactly match the specified value.
			 */
			'test': function(value, required) {
				var result, matched, a;

				if (required === true || required === false) {
					// any value is required
					result = (required ? (value[0] !== "") : (value[0] === ""));
				} else if (typeof required === "string") {
					// a particular string value is required
					result = (value[0] === required);
				} else if ($.isArray(required)) {
					// an array of values is required
					matched = 0;

					for (a = 0; a < required.length; a+= 1) {
						if ($.inArray(required[a], value) !== -1) {
							matched += 1;
						}
					}

					result = (matched === required.length);
				} else {
					result = true;
				}

				return result;
			}
		},

		/**
		 * Validates the minimum character/item length of the field value(s).
		 * @namespace WS.Form.validators.min_length
		 */
		'min_length': {
			/**
			 * @function WS.Form.validators.min_length.test
			 * @param {mixed} value - Field value.
			 * @param {number} required - Required minimum length of characters or array items.
			 * @returns {boolean} `true` if the validation succeeds, `false` otherwise.
			 */
			'test': function(value, required) {
				return (value[0].length >= required || value.length >= required);
			}
		},

		/**
		 * Validates the maximum character/item length of the field value(s).
		 * @namespace WS.Form.validators.max_length
		 */
		'max_length': {
			/**
			 * @function WS.Form.validators.max_length.test
			 * @param {mixed} value - Field value.
			 * @param {number} required - Required maximum length of characters or array items.
			 * @returns {boolean} `true` if the validation succeeds, `false` otherwise.
			 */
			'test': function(value, required) {
				return (value[0].length <= required || value.length <= required);
			}
		},

		/**
		 * Validates an email address.
		 * @namespace WS.Form.validators.email
		 */
		'email': {
			/**
			 * @function WS.Form.validators.email.test
			 * @param {mixed} value - Field value.
			 * @param {boolean} required - `true` to require an email address.
			 * @returns {boolean} `true` if the validation succeeds, `false` otherwise.
			 */
			'test': function(value, required) {
				return !!(value[0].match(/^[^\s]+@[^\s]+(\.[a-z.]*)?$/i));
			}
		}
	};

	/**
	 * @member {object} WS.Form.tipTypes
	 * @property {number} message - The tip is a general message.
	 * @property {number} warning - The tip is an avoidable warning.
	 * @property {number} error - The tip is an unavoidable error.
	 * @description
	 * A set of tip type values, used to control the class/display of the popup messages.
	 */
	Form.tipTypes = {
		'message': 0,
		'warning': 1,
		'error': 2
	};

	/**
	 * @typedef {object} WS.Form.Popup.Settings
	 * @property {object} classes
	 * @property {object} selectors
	 * @property {WS.Form.Popup.alignments} [alignment=WS.Form.Popup.alignments.top_left]
	 * @property {object} styles
	 */

	/**
	 * @typedef {object} WS.Form.Popup.showOptions
	 * @property {jQuery} $context
	 * @property {WS.Form.Popup.alignments} [alignment] - Defaults to the alignment set on
	 * instantiation.
	 * @property {WS.Form.Popup.types} [type=WS.Form.Popup.types.message]
	 * @property {boolean} [replace=false] - When `true`, will replace the contents of the popup
	 * with the new message.
	 */

	/**
	 * @typedef {Function} WS.Form.Popup.ComputePositionAfter
	 * @param {Object} css - Recommended CSS for positioning this element.
	 * @param {Form.Popup.alignments} aligmemnt - Alignment option as used by the positioning
	 * calculation.
	 * @description
	 * Executed at the end of a popup position computation, initially fired by
	 * {@link WS.Form.Popup#computePosition}
	 */

	/**
	 * @class WS.Form.Popup
	 * @param {jQuery} $form - Form element
	 * @param {WS.Form.Popup.Settings} - settings
	 * @description
	 * Form popup helper class. An instance of this class is automatically created within the
	 * `WS.Form` instance ({@link WS.Form#popup}). Unless you require a discrete popup management
	 * class, it's usually easier to use the existing instance.
	 * This optional class contains helper methods which allow you to generate and display popup
	 * "tooltips" in the appropriate place on a given form.
	 */
	Form.Popup = function($form, settings) {
		var cls, my;

		cls = {
			generateMarkup: function() {
				return my.generateMarkup.apply(this, arguments);
			},

			computePosition: function() {
				return my.computePosition.apply(this, arguments);
			}
		};

		my = {
			defaults: {
				'classes': {
					'types': {}
				},
				'selectors': {
					'submit': [
						'button[type=\'submit\']',
						'input[type=\'submit\']'
					].join(", ")
				},
				'alignment': Form.Popup.alignments.top_left
			},

			enable: function() {
				my.defaults.classes.types[Form.tipTypes.message] = "message";
				my.defaults.classes.types[Form.tipTypes.warning] = "warning";
				my.defaults.classes.types[Form.tipTypes.error] = "error";

				settings = ob_set(settings || {}, my.defaults);

				my.createUI();
			},

			createUI: function() {
				my.ui = {
					'$submit': $form.find(settings.selectors.submit).first()
				};
			},

			/**
			 * @function WS.Form.Popup#computePosition
			 * @param {jQuery} $popup - Popup jQuery object representing a single element.
			 * @param {WS.Form.Field} [field] - Contextual field definition.
			 * @param {WS.Form.Popup.alignments} [alignment] - Alignment definition. This controlls
			 * how the popup should be aligned to the `field` field(s).
			 * @param {WS.Form.Popup.ComputePositionAfter} [after] - Callback function, fired after
			 * position is computed.
			 * @description
			 * Computes the contextual field's *absolute* offset to the containing `<form>` HTMLElement
			 * given `alignment` options and uses the `after` callback to provide recommended CSS in
			 * order to accurately position the `$popup` element.
			 * @example
			 * // using the built-in popup instance
			 * cls.popup("computePosition",
			 * 	$("#frm-one-popup").append(cls.popup("generateMarkup", field.tips)),
			 * 	field,
			 * 	function(css) {
			 * 		$("#frm-one-popup").css(css);
			 * 	}
			 * );
			 */
			computePosition: function($popup, field, alignment, after) {
				var a, $context, metrics, css;

				// populate arguments
				for (a = 0; a < arguments.length; a += 1) {
					if (typeof arguments[a] === "object" && arguments[a] instanceof $) {
						$popup = arguments[a];
					} else if (typeof arguments[a] === "object") {
						field = arguments[a];
					} else if (typeof arguments[a] === "number") {
						alignment = arguments[a];
					} else if (typeof arguments[a] === "function") {
						after = arguments[a];
					}
				}

				// set default alignment type
				if (alignment === undefined) {
					if (field && field.popup && "alignment" in field.popup) {
						alignment = field.popup.alignment;
					} else {
						alignment = settings.alignment;
					}
				}

				// set css result
				css = {
					'position': 'absolute'
				};

				if (field && field.$fields && field.$fields.length) {
					$context = field.$fields.eq(0);
				} else {
					$context = my.ui.$submit;
				}

				// obtain metrics for Form, context and popup
				metrics = {
					context: $context.formOffset(),
					form: {
						'width': $form.width()
					},
					popup: {
						'height': $popup.outerHeight(),
						'width': $popup.outerWidth()
					}
				};

				metrics.context.height = $context.outerHeight();
				metrics.context.width = $context.outerWidth();

				css = my.getAlignmentCss(css, alignment, metrics.context, metrics.popup);

				if (typeof after === "function") {
					after.apply(cls, [css, alignment]);
				}
			},

			getAlignmentCss: function(css, alignment, context, popup) {
				switch (alignment) {
					case Form.Popup.alignments.bottom_left:
						css = ob_set({
							'top': (context.top + context.height) + 'px',
							'left': context.left + 'px'
						}, css);
						break;

					case Form.Popup.alignments.bottom_right:
						css = ob_set({
							'top': (context.top + context.height) + 'px',
							'left': (context.left - (popup.width - context.width)) + 'px'
						}, css);
						break;

					case Form.Popup.alignments.top_left:
						css = ob_set({
							'top': ((context.top - popup.height)) + 'px',
							'left': context.left + 'px'
						}, css);
						break;

					case Form.Popup.alignments.top_center:
						css = ob_set({
							'top': ((context.top - popup.height)) + 'px',
							'left': (context.left - (popup.width / 2) + (context.width / 2)) + 'px'
						}, css);
						break;

					case Form.Popup.alignments.bottom_center:
						css = ob_set({
							'top': (context.top + context.height) + 'px',
							'left': (context.left - (popup.width / 2) + (context.width / 2)) + 'px'
						}, css);
						break;

					case Form.Popup.alignments.top_right:
						css = ob_set({
							'top': ((context.top - popup.height)) + 'px',
							'left': (context.left - (popup.width - context.width)) + 'px'
						}, css);
						break;

					default:
						css.position = "static";
				}

				return css;
			},

			/**
			 * Generate markup HTML for inserting into a popup, based on tip {@link WS.Form.tip} data
			 * passed to it.
			 * @function WS.Form.Popup#generateMarkup
			 * @param {WS.Form.tip[]} tips - Tip content (as generated into {@link WS.Form.Field items})
			 * @returns {jQuery} Generated jQuery collection of HTMLElement elements.
			 */
			generateMarkup: function(tips, element_name) {
				var a,
					$collection = $();

				element_name = element_name || "p";

				if ($.isArray(tips)) {
					for (a = 0; a < tips.length; a += 1) {
						$collection = $collection.add(
							$("<" + element_name + ">")
								.addClass(my.defaults.classes.types[tips[a].type])
								.html(tips[a].message)
						);
					}

					return $collection;
				} else {
					return false;
				}
			}
		};

		my.enable();

		return cls;
	};

	/**
	 * @member {object} WS.Form.Popup.alignments
	 * @property {number} top_left - Align to the top left of the contextual HTMLElement.
	 * @property {number} top_right - Align to the top right of the contextual HTMLElement.
	 * @property {number} bottom_left - Align to the bottom left of the contextual HTMLElement.
	 * @property {number} bottom_right - Align to the bottom right of the contextual HTMLElement.
	 * @property {number} top_center - Align to the top center of the contextual HTMLElement.
	 * @property {number} bottom_center - Align to the bottom center of the contextual HTMLElement.
	 * @description
	 * A set of alignment values used to position the popup dialog box opened by
	 * {@link WS.Form.Popup}. In the case that the contextual HTMLElement is the `Form`, the popup
	 * is aligned to the first located submission button.
	 */
	Form.Popup.alignments = {
		'top_left': 0,
		'top_right': 1,
		'bottom_left': 2,
		'bottom_right': 3,
		'top_center': 4,
		'bottom_center': 5,
		'before': 6,
		'after': 7
	};

	$.WS.createModule("form", function($) {
		return new Form($);
	});

	window.WS.Form = Form;
}(window.jQuery, window.WS, window.ob_set));