/*!
	Solid technique for loading Google Maps dynamically.

	see:
		http://stackoverflow.com/questions/3922764/load-google-maps-v3-dynamically-with-ajax/12602845#12602845
*/

var gMapsLoaded = false;

window.gMapsCallback = function() {
	gMapsLoaded = true;
	$(window).trigger('gMapsLoaded');
}

window.loadGoogleMaps = function() {
	if (gMapsLoaded) return window.gMapsCallback();

	var script_tag = document.createElement('script');
		script_tag.setAttribute("type", "text/javascript");
		script_tag.setAttribute("src", "//maps.google.com/maps/api/js?callback=gMapsCallback&key=AIzaSyDrNJv_eTJfRJAw_Qj4P7sOf1kr4F36pv8");

	(document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
}