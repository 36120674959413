(function($, WS, ob_set) {
	'use strict';

	// require WS utility namespace
	if (typeof WS === 'undefined') {
		throw new Error('Whitespace utility namespace does not exist');
	}

	// require jQuery pub/sub/unsub
	if (typeof $.publish === 'undefined' ||
		typeof $.subscribe === 'undefined' ||
		typeof $.unsubscribe === 'undefined') {
		throw new Error('jQuery Pub/Sub utility does not exist');
	}

	/**
	 * remote
	 * Submit forms via an XHR request
	 */
	var remote = function(settings) {
		var my;

		my = {
			forms: null,

			enable: function(settings) {
				remote.settings = ob_set(settings || {}, remote.defaults);

				// delegate the form on submit events, useful
				// if the response contains an html fragement
				// note: delegated events don't work with SVG
				my.forms = $(remote.settings.selectors.delegate).on('submit',
					remote.settings.selectors.form, remote.submit);
			}
		}

		my.enable(settings);
	};

	/**
	 * remote.submit
	 * Triggered when the target form submits
	 */
	remote.submit = function(event) {
		var $this = $(this), url, data = {};

		// prep a form for remoting
		if ($this.is('form')) {
			// disable and add spinner to any buttons we have
			$this.find('button')
				.prop('disabled', 'disabled')
				.html('<i class="fa fa-refresh fa-spin"></i>');

			url = $this.prop('action');
			data = $this.serialize();
		} else {
			url = $this.prop('href');
		}

		// make request, pass the form data along as is.
		// this will also pass the csrf token along.
		$.ajax({
			// this will get overrode by input _method, but
			// should default to POST for remote calls
			'method': 'POST',

			// make xhr request with the form data and action
			'url': url,
			'data': data
		})
		// successful response
		.done(function(data) {
			if (typeof remote.settings.callbacks.done === 'function') {
				remote.settings.callbacks.done.apply(this, [$this, data]);
			}
		})
		// response with errors
		.fail(function(jqXHR) {
			if (typeof remote.settings.callbacks.fail === 'function') {
				remote.settings.callbacks.done.apply(this, [$this, jqXHR]);
			}
		});

		event.preventDefault();
	};

	/**
	 * The default WS.remote settings
	 * @typedef WS.remote.defaults
	 * @property {object} selectors - Default selector settings.
	 */
	remote.defaults = {
		'selectors': {
			'delegate': 'body',             // default event delegate
			'form':     'form[data-remote]' // default form selector
		}
	};

	/**
	 * The default WS.remote events
	 * @typedef WS.remote.events
	 */
	remote.events = {
		'success': 'remote.success', // success channel
		'error':   'remote.error',   // error channel
		'login': {
			'success': 'remote.login.success', // success channel
			'error':   'remote.login.error',   // error channel
		}
	};

	$.WS.createModule('remote', remote);
	window.WS.remote = remote;
}(
	window.jQuery,
	window.WS,
	window.ob_set
));