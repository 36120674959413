(function($, underscore, WS, ob_set) {
	'use strict';

	// require WS utility namespace
	if (typeof WS === 'undefined') {
		throw new Error('Whitespace utility namespace does not exist');
	}

	// reference top level template variables as part of a "context" object,
	// this is faster because it will traverse a scope chain when searching.
	underscore.templateSettings.variable = 'context';

	/**
	 */
	var dialog = function(settings) {
		var cls, my;

		cls = {
			confirm: function() {
				return my.dialog.confirm.apply(cls, arguments);
			}
		};

		my = {

			$form: null,

			// precompiled templates
			templates: {},

			enable: function() {
				settings = ob_set(settings || {}, dialog.defaults);

				// set the default/fallback methods
				var fn = {};
				fn.confirm = my.fallback.confirm;

				my.parseUi(fn);
			},

			parseUi: function(fn) {
				// find and precompile any underscore templates we have
				if (typeof underscore !== 'undefined') {
					var tpl;
					underscore.each(settings.selectors.templates, function(value, key) {
						if (typeof value !== 'undefined') {
							if ((tpl = $(value).html()).length) {
								my.templates[key] = underscore.template(tpl);
							}
						}
					});

					// if we have templates assign these over the fallbacks
					if (underscore.size(my.templates) > 0) {
						fn.confirm = my.dialog.confirm;
					}
				}

				// is this a form?
				$(settings.selectors.confirm).each(function(index, el) {
					if ($(el).is('form')) {
						my.$form = $(el);
						my.$form.find(settings.selectors.confirm).on('click', fn.confirm);
					}
				});
			},

			dialog: {
				confirm: function(event) {
					var confirmed = false, $dialog;

					// don't let this submit if it is a form
					my.$form.on('submit', function() { return false; });

					// create a dialog
					$dialog = $(my.templates.confirm({
						'heading': my.$form.data('heading') || settings.messages.confirm.heading,
						'message': my.$form.data('message') || settings.messages.confirm.message
					}))
						.hide()
						.appendTo('body')
						.fadeIn(180);

					$dialog.find('.allow').on('click', function() {
						$dialog.fadeOut(120).remove();

						// does this form expect any actions
						if (my.$form.find(settings.selectors.confirm).hasClass('submit-remove')) {
							$('input#frm-action').val('remove');
							$('input#frm-params').val(my.$form.data('events[]'));
						}

						my.$form.off('submit').submit();
					});

					$dialog.find('.cancel').on('click', function() {
						$dialog.fadeOut(120).remove();
					});

					event.preventDefault();
				}
			},

			fallback: {
				// fire a native confirm
				confirm: function(event, message) {
					return confirm(message || settings.messages.confirm);
				},
			}
		}

		my.enable(settings);
		return cls;
	};

	/**
	 * The default WS.dialog settings
	 * @typedef WS.dialog.defaults
	 * @property {object} selectors - Default selector settings.
	 */
	dialog.defaults = {
		'messages': {
			'confirm': {
				'heading': 'Are you sure?',
				'message': 'Default confirm message text.'
			}
		},
		'selectors': {
			'confirm': '.confirm'
		}
	};

	$.WS.createModule('dialog', dialog);
	window.WS.dialog = dialog;
}(
	window.jQuery,
	window._,
	window.WS,
	window.ob_set
));
